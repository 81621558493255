import { template as template_066ec96c4671422a9be081af4ffb4c3c } from "@ember/template-compiler";
const SidebarSectionMessage = template_066ec96c4671422a9be081af4ffb4c3c(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

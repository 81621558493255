import { template as template_2c05e1e5ce0b401da82992cda07b8d45 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_2c05e1e5ce0b401da82992cda07b8d45(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;

import { template as template_6a22110b93e449f49dcba8b8de4b29ad } from "@ember/template-compiler";
const FKControlMenuContainer = template_6a22110b93e449f49dcba8b8de4b29ad(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

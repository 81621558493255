import { template as template_a5dd5d33f31c4de495a08ca88d1e6a69 } from "@ember/template-compiler";
const FKText = template_a5dd5d33f31c4de495a08ca88d1e6a69(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
